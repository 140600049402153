.habit {
  margin: 10px 0 0 0px;
  width: 95%;
}

.to-delete {
  text-decoration: line-through;
}

.td-existing-habit {
  background-color: lightgray;
}

.bullet-button {
  cursor: pointer;
}

.commit-graph {
  margin-left: 50px;
}

/* .habit-graphs {
  margin-bottom: 150px;
} */

.habit-h1 {
  margin: 10px 0 0 10px;
}

.habit-title {
  margin-left: 14px;
}

@media only screen and (max-width: 850px) {
}

@media only screen and (max-width: 1200px) {
}
